



































































































import { FormBlock } from '@/app/components';
import { minValueValidator, requiredValidator } from '@/app/validators';
import { LockClosedIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { extend, ValidationProvider } from 'vee-validate';
import { SQLConnectionDetails } from '../../../types';

extend('min_value', minValueValidator);
extend('required', requiredValidator);

export default defineComponent({
    name: 'SQLConnectionDetails',
    model: {
        prop: 'connection',
        event: 'change',
    },
    props: {
        connection: {
            type: [Object, String] as PropType<SQLConnectionDetails>,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        FormBlock,
        ValidationProvider,
        LockClosedIcon,
    },
    setup: (props, { emit }) => {
        const connectionDetails: Ref<SQLConnectionDetails> = computed({
            get: () => props.connection,
            set: (newConnection: SQLConnectionDetails) => {
                emit('change', newConnection);
            },
        });

        const inVault = computed(() => R.type(connectionDetails.value) === 'String');

        return {
            connectionDetails,
            inVault,
        };
    },
});
