



























































































































import { PropType, computed, defineComponent } from '@vue/composition-api';
import { AnonymisationField, AnonymisationFieldStatistics, MappedFieldConfiguration } from '../../types';
import { FieldPath } from '..';
import { isNil } from 'ramda';
import { CheckCircleIcon } from '@vue-hero-icons/outline';
import MaskingRule from './rules/MaskingRule.vue';
import IntervalRule from './rules/IntervalRule.vue';
import NumericalGroupRule from './rules/NumericalGroupRule.vue';
import DateRule from './rules/DateRule.vue';
import IdentifierRule from './rules/IdentifierRule.vue';
import SensitiveRule from './rules/SensitiveRule.vue';
import BooleanGroupRule from './rules/BooleanGroupRule.vue';

export default defineComponent({
    name: 'AnonymisationFieldOverview',
    model: {
        prop: 'selected',
        event: 'changed',
    },
    components: { FieldPath, CheckCircleIcon, IdentifierRule, SensitiveRule },
    props: {
        selected: { type: String, required: false },
        field: {
            type: Object as PropType<AnonymisationField & MappedFieldConfiguration & { title: string }>,
            required: true,
        },
        disabled: { type: Boolean, default: false },
        toggleSelection: { type: Boolean, default: false },
        showRules: { type: Boolean, default: false },
        statistics: { type: Object as PropType<AnonymisationFieldStatistics> },
        rules: { type: Array as PropType<any[]>, default: () => [] },
    },
    setup: (props) => {
        const isSelected = computed(() => props.selected === props.field.anonymisationIdentifier);
        const formatNumber = (num: number) => {
            if (num && !Number.isInteger(num)) return num.toFixed(2);
            return num;
        };
        const anonymisationTypeClasses = computed(() => {
            if (props.field.anonymisationType === 'insensitive')
                return 'text-green-600 bg-green-100 border border-green-200 rounded';
            if (props.field.anonymisationType === 'quasi-identifier')
                return 'text-primary-600 bg-primary-100 border border-primary-200 rounded';
            if (props.field.anonymisationType === 'identifier')
                return 'text-orange-600 bg-orange-100 border border-orange-200 rounded';
            if (props.field.anonymisationType === 'sensitive')
                return 'text-red-600 bg-red-100 border border-red-200 rounded';

            return '';
        });

        const ruleComponent = computed(() => {
            switch (props.field.generalisation) {
                case 'masking':
                    return MaskingRule;
                case 'interval':
                    return IntervalRule;
                case 'numerical-group':
                    return NumericalGroupRule;
                case 'boolean-group':
                    return BooleanGroupRule;
                case 'datetime':
                case 'date':
                case 'time':
                    return DateRule;
                default:
                    return undefined;
            }
        });

        return { isSelected, anonymisationTypeClasses, ruleComponent, formatNumber, isNil };
    },
});
