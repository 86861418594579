



import { ApiHarvesterParameter } from '@/modules/apollo/types';
import { PropType, defineComponent, ref, watch } from '@vue/composition-api';
import { filter, is, isNil, pathEq } from 'ramda';

export default defineComponent({
    name: 'UrlPreview',
    props: {
        url: { type: String, default: '' },
        parameters: { type: Array as PropType<ApiHarvesterParameter[]>, default: () => [] },
    },
    setup(props) {
        const preview = ref<string>('');

        const paramValue = (param: ApiHarvesterParameter) => {
            if (is(Object, param.value.value)) {
                const optionValue = param.value.value as { option: string | undefined; value: string | undefined };
                if (isNil(optionValue.value))
                    return {
                        preview: `${optionValue.option}`,
                        tooltip: `The value for this parameter will be dynamically generated as ${optionValue.option}`,
                    };
                return {
                    preview: `${optionValue.option}: ${optionValue.value}`,
                    tooltip: `The value for this parameter will be dynamically generated as ${optionValue.option}: ${optionValue.value}`,
                };
            }
            if (!isNil(param.value.value)) {
                if (`${param.value.value}`.startsWith('vault://')) {
                    return {
                        preview: `${param.key.name}`,
                        tooltip: `Sensitive value based on ${
                            param.key.type === 'url' ? 'URL' : param.key.type
                        } parameter with key "${param.key.name}"`,
                    };
                }
                return {
                    preview: `${param.value.value}`,
                    tooltip: `Value based on ${param.key.type === 'url' ? 'URL' : param.key.type} parameter with key "${
                        param.key.name
                    }"`,
                };
            }
            return { preview: param.key.name, tooltip: `Please define parameter value` };
        };

        const colorClass = (param: ApiHarvesterParameter) => {
            if (param.value?.value && `${param.value.value}`.startsWith('vault://')) {
                return 'bg-red-200';
            }
            switch (param.value.category) {
                case 'dynamic':
                    return 'bg-green-200';
                case 'authentication':
                    return 'bg-purple-200';
                case 'pagination':
                    return 'bg-orange-200';
                case 'static':
                    return 'bg-blue-200';
                default:
                    return 'bg-neutral-300';
            }
        };

        /**
         * Upon any change of the url or parameters we recreate the preview html
         * Since we replace parts of the url with formatted strings and also
         * add the query params we do it here.
         *
         * TODO might be doable in a different way and directly in the template perhaps
         */
        watch(
            () => [props.url, props.parameters],
            ([newUrl, newParameters]) => {
                const previewUrl = filter(
                    pathEq(['key', 'type'], 'url'),
                    newParameters as ApiHarvesterParameter[],
                ).reduce((url: string, param: ApiHarvesterParameter) => {
                    const { preview: paramPreview, tooltip } = paramValue(param);
                    return url.replaceAll(
                        `{${param.key.name}}`,
                        `<span class='p-1 rounded-md shadow-sm ${colorClass(
                            param,
                        )}' title='${tooltip}'>${paramPreview}</span>`,
                    );
                }, newUrl as string);
                const queryStrings = filter(
                    pathEq(['key', 'type'], 'query'),
                    newParameters as ApiHarvesterParameter[],
                ).map((param: ApiHarvesterParameter) => {
                    const { preview: paramPreview, tooltip } = paramValue(param);
                    return `<span>${
                        param.key.name
                    }</span><span>=</span><span class='p-1 rounded-md shadow-sm ${colorClass(
                        param,
                    )}' title='${tooltip}'>${paramPreview}</span>`;
                });
                preview.value = `${previewUrl}${queryStrings.length > 0 ? '?' : ''}${queryStrings.join('&')}`;
            },
            { immediate: true, deep: true },
        );

        return { preview };
    },
});
