var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"h-20 m-1 bg-white border border-gray-300 shadow cursor-pointer",class:_vm.classes,on:{"click":_vm.conceptClicked}},[_c('div',{staticClass:"flex h-full p-2 border-l-4",class:{
                'border-primary-700': _vm.selected,
                'border-transparent': !_vm.selected,
            }},[_c('div',{staticClass:"flex flex-col w-2/5"},[_c('div',[_c('h2',{staticClass:"text-lg font-bold text-neutral-800 min-h-7"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex items-center space-x-2"},[(_vm.failedTransformationReason)?_c('ExclamationIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.failedTransformationReason),expression:"failedTransformationReason"}],staticClass:"w-5 h-5 text-red-600"}):_vm._e(),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                                        content: _vm.fullSourcePath,
                                        classes: 'max-w-lg break-all',
                                        placement: 'right-start',
                                    }),expression:"{\n                                        content: fullSourcePath,\n                                        classes: 'max-w-lg break-all',\n                                        placement: 'right-start',\n                                    }"}],staticClass:"break-all line-clamp-1"},[_vm._v(_vm._s(_vm.concept.source.title))]),_c('span',{staticClass:"px-2 text-sm capitalize rounded text-neutral-100 bg-neutral-600"},[_vm._v(_vm._s(_vm.concept.source.type))])],1)])]),_c('FieldPath',{attrs:{"field":_vm.concept.source,"basePath":_vm.basePath}})],1)]),_c('div',{staticClass:"w-1/5"},[(_vm.concept.target.id)?_c('PredictionScore',{attrs:{"score":_vm.concept.prediction && _vm.concept.prediction.confidence,"user-defined":_vm.concept.temp && _vm.concept.temp.userDefined}}):(_vm.selected)?_c('div',{staticClass:"h-full mx-2 align-middle border border-dashed",class:{
                        'border-primary-700 bg-primary-100': _vm.inDropZone,
                        'border-neutral-400 bg-neutral-100': !_vm.inDropZone,
                    }},[_c('Draggable',{ref:"dropAreaRef",staticClass:"flex flex-wrap content-center justify-center h-full text-center",class:{ 'opacity-0': !_vm.inDropZone && _vm.dragActive },attrs:{"group":{ name: 'newConcepts', put: true, pull: false },"list":_vm.droppedConcept},on:{"add":_vm.add}},[(_vm.droppedConcept.length === 0 && !_vm.dragActive)?_c('div',{staticClass:"text-sm italic text-neutral-500"},[_vm._v(" Drag and drop a field here ")]):_vm._e()])],1):_vm._e()],1),_c('div',{staticClass:"w-2/5"},[_c('div',[_c('h2',{staticClass:"flex items-center justify-between text-lg font-bold text-neutral-800 min-h-7"},[_c('div',{staticClass:"flex flex-row items-start justify-between flex-1 overflow-hidden"},[(_vm.concept.target.id)?_c('div',{staticClass:"flex flex-row space-x-2"},[_c('div',{staticClass:"flex items-center space-x-2 overflow-hidden"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.fullTargetPath),expression:"fullTargetPath"}],staticClass:"break-all line-clamp-1"},[_vm._v(_vm._s(_vm.concept.target.title))]),(_vm.concept.transformation && _vm.concept.transformation.order)?_c('span',[_vm._v("["+_vm._s(_vm.concept.transformation.order)+"] ")]):_vm._e(),_c('span',{staticClass:"flex flex-1 px-2 text-sm capitalize rounded",class:{
                                            'text-red-700 bg-red-200': !_vm.sameSourceTargetType,
                                            'text-neutral-100 bg-neutral-600': _vm.sameSourceTargetType,
                                        }},[_vm._v(_vm._s(_vm.concept.target.type))])]),_c('button',{staticClass:"p-1 text-neutral-500 hover:text-red-700",attrs:{"type":"button"},on:{"click":function($event){_vm.$emit('clear-mapping', false);
                                        $event.stopImmediatePropagation();}}},[_c('XIcon',{staticClass:"w-4 h-4"})],1)]):_vm._e(),(
                                    _vm.concept.temp &&
                                    _vm.concept.temp.invalid &&
                                    String(_vm.concept.source.id) in _vm.validationErrors
                                )?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.validationErrors[String(_vm.concept.source.id)].message),expression:"validationErrors[String(concept.source.id)].message"}],staticClass:"px-5 text-sm font-normal text-center text-white bg-red-600 rounded"},[_vm._v(" "+_vm._s(_vm.validationErrors[String(_vm.concept.source.id)].message))]):_vm._e()])]),_c('div',{staticClass:"flex items-center mt-2 space-x-1 text-sm text-neutral-600"},[_c('FieldPath',{attrs:{"field":_vm.concept.target}})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }