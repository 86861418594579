var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isFinalized || !_vm.isDisabled)?_c('ValidationObserver',{ref:"paramValidationRef",staticClass:"flex flex-col space-y-2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('MultivalueEditor',{attrs:{"items":_vm.additionalResponseData,"uniqueCombination":['key'],"requiredFields":_vm.getMultivalueEditorRequiredFields,"theme":"green","readonly":_vm.isFinalized,"done-type":"button-bottom","canAdd":!invalid,"canUpdate":!invalid,"canAddAnother":!_vm.invalidParams},on:{"add":_vm.add,"remove":_vm.remove,"edit":_vm.edit},scopedSlots:_vm._u([{key:"addAnother",fn:function(){return [(_vm.invalidParams)?_c('div',{staticClass:"text-sm text-red-700"},[_vm._v(" Please rename or remove parameters that already exist in response ")]):_c('div',{staticClass:"flex flex-row space-x-2 text-sm cursor-pointer w-80"},[_c('PlusCircleIcon',{staticClass:"w-5 h-5 text-green-700"}),_c('span',{staticClass:"font-bold text-green-700 hover:underline"},[_vm._v(" Add additional parameter as data ")])],1)]},proxy:true},(!_vm.isFinalized)?{key:"listIcon",fn:function(ref){
var data = ref.data;
return [(_vm.siblingKeys.includes(data.item.key.toLowerCase()))?_c('ExclamationIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                        'The additional parameter name already exists in the response, i.e. in the original dataset’s columns.'
                    ),expression:"\n                        'The additional parameter name already exists in the response, i.e. in the original dataset’s columns.'\n                    "}],staticClass:"w-5 h-5 text-red-700"}):_c('PlusCircleIcon',{staticClass:"w-5 h-5 text-green-700"})]}}:null,{key:"view",fn:function(ref){
                    var data = ref.data;
return [_c('div',{staticClass:"flex flex-row space-x-2 text-sm"},[(data.item.type === 'static')?[_c('span',[_vm._v("Parameter "),_c('span',{staticClass:"font-bold text-primary-700"},[_vm._v(" "+_vm._s(data.item.key))]),_vm._v(", with "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(data.item.type))]),_vm._v(" value "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(data.item.value))]),_vm._v(".")])]:(data.item.type === 'request')?[_c('span',[_vm._v("Parameter "),_c('span',{staticClass:"font-bold text-primary-700"},[_vm._v(" "+_vm._s(data.item.key))]),_vm._v(", with "),_c('span',{staticClass:"font-bold"},[_vm._v("request parameter")]),_c('span',{staticClass:"italic"},[_vm._v(" "+_vm._s(data.item.value.key.name)),(data.item.value.value.category)?[_vm._v(" ("+_vm._s(data.item.value.value.category)+")")]:_vm._e()],2),_vm._v(".")])]:[_c('span',[_vm._v("Parameter "),_c('span',{staticClass:"font-bold text-primary-700"},[_vm._v(" "+_vm._s(data.item.key))]),_vm._v(", with "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(data.item.type))]),_vm._v(" value "),(_vm.getValuePrefixLabel(data.item.dynamicType))?_c('span',[_vm._v(_vm._s(_vm.getValuePrefixLabel(data.item.dynamicType))+" ")]):_vm._e(),(data.item.value)?_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(data.item.value)+" ")]):_vm._e(),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.getValueLabel(data.item.dynamicType)))])])]],2)]}},{key:"edit",fn:function(ref){
                    var data = ref.data;
return [_c('div',{staticClass:"flex flex-col space-y-2 text-sm"},[(data.mode === 'create')?_c('div',{staticClass:"flex flex-row space-x-2 text-sm cursor-pointer w-80"},[_c('PlusCircleIcon',{staticClass:"w-5 h-5 text-green-700"}),_c('span',{staticClass:"font-bold text-green-700 underline"},[_vm._v(" Add additional parameter as data ")])],1):_c('span',{staticClass:"font-bold text-green-700 underline"},[_vm._v(" Add additional data ")]),_c('div',{staticClass:"flex flex-row max-w-lg space-x-2"},[_c('div',{staticClass:"w-1/2"},[_c('label',{staticClass:"block mb-2 text-xs font-bold tracking-wide uppercase text-neutral-600",attrs:{"for":"key"}},[_vm._v(" Parameter ")]),_c('ValidationProvider',{attrs:{"rules":"required|max_param_name:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"relative"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(data.item.key),expression:"data.item.key",modifiers:{"trim":true}}],staticClass:"block w-full px-2 py-1 pr-8 text-sm form-input",class:{ 'border-red-700': invalid && errors.length > 0 },attrs:{"id":"key","type":"text"},domProps:{"value":(data.item.key)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data.item, "key", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(errors.length > 0)?_c('InputErrorIcon',{attrs:{"tooltip":errors[0]}}):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"w-1/2"},[_c('label',{staticClass:"block mb-2 text-xs font-bold tracking-wide uppercase text-neutral-600",attrs:{"for":"type"}},[_vm._v(" Value Type ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"relative"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(data.item.type),expression:"data.item.type"}],staticClass:"block w-full px-2 py-1 text-sm form-select",class:{
                                            'text-neutral-500': data.item.type === null,
                                            'border-red-700': errors.length > 0,
                                        },attrs:{"id":"type"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(data.item, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){data.item.value = null}]}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Select type...")]),_vm._l((_vm.parameterTypes),function(type){return _c('option',{key:type.value,staticClass:"text-neutral-800",domProps:{"value":type.value}},[_vm._v(" "+_vm._s(type.label)+" ")])})],2),(errors.length > 0)?_c('InputErrorIcon',{attrs:{"tooltip":errors[0]}}):_vm._e()],1)]}}],null,true)})],1)]),(data.item.type === 'dynamic')?_c('div',{staticClass:"max-w-lg"},[_c('label',{staticClass:"block mb-2 text-xs font-bold tracking-wide uppercase text-neutral-600",attrs:{"for":"dynamicType"}},[_vm._v(" Dynamic Value ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('div',{staticClass:"relative"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(data.item.dynamicType),expression:"data.item.dynamicType"}],staticClass:"block w-full px-2 py-1 text-sm form-select",class:{
                                        'text-neutral-500':
                                            _vm.getNeedsValue(data.item.dynamicType) && data.item.value === null,
                                        'border-red-700': errors.length > 0,
                                    },attrs:{"id":"dynamicType"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(data.item, "dynamicType", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Select dynamic value...")]),_vm._l((_vm.allDynamicValues),function(type,index){return _c('option',{key:index,staticClass:"text-neutral-800",domProps:{"value":type.value}},[_vm._v(" "+_vm._s(type.label)+" ")])})],2),(errors.length > 0)?_c('InputErrorIcon',{attrs:{"tooltip":errors[0]}}):_vm._e()],1)]}}],null,true)})],1):_vm._e(),(data.item.type === 'dynamic' && data.item.dynamicType)?_c('div',{staticClass:"flex flex-row max-w-lg space-x-2"},[(_vm.getNeedsValue(data.item.dynamicType))?_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"block mb-2 text-xs font-bold tracking-wide uppercase text-neutral-600",attrs:{"for":"value"}},[_vm._v(" Value ")]),_c('ValidationProvider',{attrs:{"rules":"required|integer|max_param_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('div',{staticClass:"relative max-w-lg"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.item.value),expression:"data.item.value"}],staticClass:"block w-full px-2 py-1 pr-8 text-sm form-input",class:{ 'border-red-700': errors.length > 0 },attrs:{"id":"value","type":"number"},domProps:{"value":(data.item.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data.item, "value", $event.target.value)}}}),(errors.length > 0)?_c('InputErrorIcon',{attrs:{"tooltip":errors[0]}}):(data.item.value && data.item.value.length > 20)?_c('InputErrorIcon',{attrs:{"colour":"text-orange-400","tooltip":"The value you have entered is quite long"}}):_vm._e()],1)]}}],null,true)})],1):_vm._e()]):_vm._e(),(data.item.type === 'static')?_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"block mb-2 text-xs font-bold tracking-wide uppercase text-neutral-600",attrs:{"for":"value"}},[_vm._v(" Value ")]),_c('ValidationProvider',{attrs:{"rules":"required|max_param_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('div',{staticClass:"relative max-w-lg"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(data.item.value),expression:"data.item.value",modifiers:{"trim":true}}],staticClass:"block w-full px-2 py-1 pr-8 text-sm form-input",class:{ 'border-red-700': errors.length > 0 },attrs:{"id":"value","type":"text"},domProps:{"value":(data.item.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data.item, "value", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(errors.length > 0)?_c('InputErrorIcon',{attrs:{"tooltip":errors[0]}}):(data.item.value && data.item.value.length > 20)?_c('InputErrorIcon',{attrs:{"colour":"text-orange-400","tooltip":"The value you have entered is quite long"}}):_vm._e()],1)]}}],null,true)})],1):_vm._e(),(data.item.type === 'request')?_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"block mb-2 text-xs font-bold tracking-wide uppercase text-neutral-600",attrs:{"for":"requestParam"}},[_vm._v(" Request Parameter ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('div',{staticClass:"relative"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(data.item.value),expression:"data.item.value"}],staticClass:"block w-full px-2 py-1 text-sm form-select",class:{
                                        'border-red-700': errors.length > 0,
                                    },attrs:{"id":"requestParam"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(data.item, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Select a request parameter...")]),_vm._l((_vm.allRequestParams),function(type,index){return _c('option',{key:index,staticClass:"text-neutral-800",domProps:{"value":type.value}},[_vm._v(" "+_vm._s(type.label)+" ")])})],2),(errors.length > 0)?_c('InputErrorIcon',{attrs:{"tooltip":errors[0]}}):_vm._e()],1)]}}],null,true)})],1):_vm._e()])]}},{key:"noItems",fn:function(){return [_c('div',{staticClass:"text-sm italic text-neutral-500"},[_vm._v("No additional data defined")])]},proxy:true}],null,true)})]}}],null,false,3260670334)}):_c('div',{staticClass:"text-sm italic text-neutral-500"},[_vm._v("Unable to add additional data to an empty response")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }