import { render, staticRenderFns } from "./MappingInfo.vue?vue&type=template&id=924b40ba&scoped=true&"
import script from "./MappingInfo.vue?vue&type=script&lang=ts&"
export * from "./MappingInfo.vue?vue&type=script&lang=ts&"
import style0 from "./MappingInfo.vue?vue&type=style&index=0&id=924b40ba&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "924b40ba",
  null
  
)

export default component.exports