var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"w-full h-20 bg-white border border-gray-300 shadow cursor-pointer",class:[_vm.classes, { border: !_vm.disabled, ' bg-neutral-100': _vm.disabled }],on:{"click":_vm.fieldClicked}},[_c('div',{staticClass:"flex h-full",class:{
                'border-primary-700': _vm.selected,
                'border-transparent': !_vm.selected,
                'border-l-4 ': !_vm.disabled,
            }},[_c('div',{staticClass:"flex flex-col p-2",class:{
                    'w-full': !_vm.isFinalized || (!_vm.cleaningDetails && !_vm.failedStats) || !_vm.supportMetrics,
                    'w-5/6': _vm.isFinalized && (_vm.cleaningDetails || _vm.failedStats) && _vm.supportMetrics,
                }},[_c('div',[_c('h2',{staticClass:"flex items-center justify-between text-lg font-bold text-neutral-800 min-h-7"},[_c('div',{staticClass:"flex items-center float-left space-x-2"},[_c('span',{staticClass:"text-primary-700"},[(_vm.showAlternateNaming && _vm.alternateName && _vm.alternateName.title)?_c('v-popover',{staticClass:"flex flex-row items-center",attrs:{"trigger":"hover focus","placement":"top"}},[_c('span',[_vm._v(" "+_vm._s(_vm.alternateName.title[0])+" "+_vm._s(_vm.alternateName.title.length > 1 ? '[1]' : '')+" ")]),_c('div',{staticClass:"max-w-lg p-1 space-y-1 break-words",attrs:{"slot":"popover"},slot:"popover"},[_c('div',[_c('span',{staticClass:"font-bold"},[_vm._v("Field Name:")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.field.title))])]),(_vm.alternateName.title.length > 1)?_c('div',[_c('span',{staticClass:"font-bold"},[_vm._v("Other Alternate Names:")]),_vm._l((_vm.alternateName.title.slice(1)),function(name,idx){return _c('span',{key:idx},[_vm._v(" "+_vm._s(name)+" ["+_vm._s(idx + 2)+"]"),(idx < _vm.alternateName.title.length - 2)?_c('span',[_vm._v(", ")]):_vm._e()])})],2):_vm._e()])]):_c('span',[_vm._v(_vm._s(_vm.field.title))])],1),_c('span',{staticClass:"px-2 text-sm capitalize rounded text-neutral-100 bg-neutral-600"},[_vm._v(" "+_vm._s(_vm.field.type)+" ")]),(_vm.usedInAdvancedRules > 0)?_c('span',{staticClass:"pl-1 text-sm text-yellow-700"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                        ("Used in " + _vm.usedInAdvancedRules + " Advanced Rule" + (_vm.usedInAdvancedRules > 1 ? 's' : ''))
                                    ),expression:"\n                                        `Used in ${usedInAdvancedRules} Advanced Rule${\n                                            usedInAdvancedRules > 1 ? 's' : ''\n                                        }`\n                                    "}],staticClass:"flex items-center space-x-1"},[_c('LinkIcon',{staticClass:"flex-grow-0 flex-shrink-0 w-4 h-4"}),_c('span',[_vm._v(_vm._s(_vm.usedInAdvancedRules))])],1)]):_vm._e(),(_vm.modified && !_vm.isFinalized)?_c('ExclamationIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                    'This field has been revised or some of its rule conditions have been removed due to changes in the Mapping step'
                                ),expression:"\n                                    'This field has been revised or some of its rule conditions have been removed due to changes in the Mapping step'\n                                "}],staticClass:"w-5 h-5 text-secondary-600"}):_vm._e()],1),(!_vm.cleaningDetails && !_vm.failedStats)?_c('div',{staticClass:"flex items-center float-right space-x-1 text-sm"},[(_vm.constraints.length > 0)?_c('span',{staticClass:"px-2 mr-1 leading-5 text-center text-green-700 capitalize bg-green-100 border border-green-300 rounded"},[_vm._v(" "+_vm._s(_vm.constraints.length)+" Cleaning Rule"),(_vm.constraints.length > 1)?_c('span',[_vm._v("s")]):_vm._e(),_vm._v(" Defined ")]):_vm._e()]):_c('div',{staticClass:"flex items-center float-right space-x-2"},[(_vm.successfulRules > 0 && _vm.failedRules === 0)?_c('span',{staticClass:"px-2 text-sm leading-5 text-green-700 capitalize bg-green-100 border border-green-300 rounded"},[_c('span',{staticClass:"hidden lg:block"},[_vm._v("All Cleaning Rules Successfully Executed")]),_c('span',{staticClass:"lg:hidden"},[_vm._v("Successful")])]):_c('span',{staticClass:"px-2 text-sm leading-5 text-red-700 capitalize bg-red-100 border border-red-300 rounded"},[_vm._v(" "+_vm._s(_vm.failedRules)+" Cleaning Rule"),(_vm.failedRules > 1)?_c('span',[_vm._v("s")]):_vm._e(),_vm._v(" Failed ")])])]),_c('FieldPath',{attrs:{"field":_vm.field,"alternateName":_vm.alternateName,"showAlternateNaming":_vm.showAlternateNaming}},[(_vm.sampleFailedConstraints.length)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                ("Includes " + (_vm.sampleFailedConstraints.length) + " rule(s) that failed during the sample run")
                            ),expression:"\n                                `Includes ${sampleFailedConstraints.length} rule(s) that failed during the sample run`\n                            "}],staticClass:"px-2 mr-1 text-sm font-bold leading-5 text-red-700 capitalize bg-red-100 border border-red-300 rounded"},[_vm._v(" "+_vm._s(_vm.sampleFailedConstraints.length)+" Rule"),(_vm.problematicSampleConstraints.length > 1)?_c('span',[_vm._v("s")]):_vm._e(),_vm._v(" Failed on Sample ")]):(_vm.sampleDropConstraints.length)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                ("Includes " + (_vm.sampleDropConstraints.length) + " rule(s) that dropped a part or the whole sample")
                            ),expression:"\n                                `Includes ${sampleDropConstraints.length} rule(s) that dropped a part or the whole sample`\n                            "}],staticClass:"px-2 mr-1 text-sm font-bold leading-5 text-yellow-700 capitalize bg-yellow-100 border border-yellow-300 rounded"},[_vm._v(" "+_vm._s(_vm.sampleDropConstraints.length)+" Dropped Rule"),(_vm.sampleDropConstraints.length > 1)?_c('span',[_vm._v("s")]):_vm._e()]):_vm._e()])],1)]),(_vm.isFinalized && (_vm.cleaningDetails || _vm.failedStats) && _vm.supportMetrics)?_c('div',{staticClass:"flex flex-col justify-between w-2/12 p-2 border-l border-gray-400"},[_c('span',{staticClass:"text-xs text-center lg:text-sm text-neutral-600"},[_vm._v(" Rows Dropped ")]),_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"mt-2 text-xl font-bold lg:text-2xl text-neutral-700"},[_vm._v(_vm._s(_vm.rowsDropped))]),(_vm.failedStats)?_c('span',{staticClass:"mt-2 text-2xl text-red-600"},[_vm._v(" (+"+_vm._s(_vm.failedRowsDropped)+")")]):_vm._e()])]):_vm._e(),(_vm.isFinalized && (_vm.cleaningDetails || _vm.failedStats) && _vm.supportMetrics)?_c('div',{staticClass:"flex flex-col justify-between w-2/12 p-2 border-l border-gray-400"},[_c('span',{staticClass:"text-xs text-center lg:text-sm text-neutral-600"},[_vm._v(" Rows with Replaced Values ")]),_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"mt-2 text-xl font-bold lg:text-2xl text-neutral-700"},[_vm._v(_vm._s(_vm.rowsReplaced))]),(_vm.failedStats)?_c('span',{staticClass:"mt-2 text-2xl text-red-600"},[_vm._v(" (+"+_vm._s(_vm.failedRowsReplaced)+")")]):_vm._e()])]):_vm._e()])]),(_vm.disabled && _vm.expanded)?_c('div',{staticClass:"h-auto space-y-2 bg-white border-gray-300 shadow"},_vm._l((_vm.constraints),function(constraint,idx){return _c('div',{key:idx,staticClass:"flex flex-row flex-1"},[_c('div',{staticClass:"px-2 pt-1",class:{
                    'w-full': !_vm.isFinalized || (!_vm.cleaningDetails && !_vm.failedStats),
                    'w-5/6': _vm.isFinalized && (_vm.cleaningDetails || _vm.failedStats) && !_vm.isConstraintFail(constraint),
                    'w-3/6': _vm.isFinalized && (_vm.cleaningDetails || _vm.failedStats) && _vm.isConstraintFail(constraint),
                    'pb-1': idx === _vm.constraints.length - 1,
                }},[_c('ViewConstraint',{attrs:{"types":[_vm.field.type],"constraint":constraint,"readonly":true,"is-fail":_vm.isConstraintFail(constraint),"index":idx,"hasStats":_vm.isFinalized && (!!_vm.cleaningDetails || !!_vm.failedStats),"fields":_vm.configuration.fields}})],1),(_vm.isConstraintFail(constraint))?_c('div',{staticClass:"float-right w-2/12 px-2 space-x-1 text-sm text-neutral-600"},[_c('span',{staticClass:"float-right px-2 text-sm text-red-700"},[_vm._v(" "+_vm._s(_vm.failedConstraints[constraint.id.toString()])+" ")])]):_vm._e(),(
                    _vm.isFinalized && constraint && constraint.outliersRule && _vm.constraintDetails(constraint) !== null
                )?_c('div',{staticClass:"w-2/12 px-2 pt-1 text-center"},[(constraint.outliersRule.type === 'DROP')?_c('span',[_vm._v(_vm._s(_vm.constraintDetails(constraint).dropped))]):_c('span',[_vm._v("—")]),(constraint.outliersRule.type === 'DROP' && _vm.failedStats)?_c('span',{staticClass:"text-red-600"},[_vm._v(" (+"+_vm._s(_vm.failedConstraintDetails(constraint).dropped)+")")]):_vm._e()]):_vm._e(),(
                    _vm.isFinalized && constraint && constraint.outliersRule && _vm.constraintDetails(constraint) !== null
                )?_c('div',{staticClass:"w-2/12 px-2 pt-1 text-center"},[(constraint.outliersRule.type !== 'DROP')?_c('span',[_vm._v(_vm._s(_vm.constraintDetails(constraint).transformed))]):_c('span',[_vm._v("—")]),(constraint.outliersRule.type !== 'DROP' && _vm.failedStats)?_c('span',{staticClass:"text-red-600"},[_vm._v(" (+"+_vm._s(_vm.failedConstraintDetails(constraint).transformed)+")")]):_vm._e()]):_vm._e()])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }