































































import { ShortTypeBadge } from '@/app/components';
import { S } from '@/app/utilities';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
    name: 'SampleTable',
    props: {
        sample: {
            type: Array as PropType<Record<string, any>>,
            default: () => [],
        },
        sampleFields: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        structure: {
            type: Object as PropType<Record<string, string>>,
            default: () => {},
        },
        additional: {
            type: Array as PropType<{ key: string; type: 'static' | 'dynamic'; displayValue: string }[]>,
            default: () => [],
        },
    },
    components: { ShortTypeBadge },
    setup() {
        return { S };
    },
});
