




























































import { AlertBanner, FormBlock, SQLEditor } from '@/app/components';
import { RetrievalType } from '@/modules/apollo/constants';
import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import { BigQueryHarvesterConfiguration } from '../../../types';
import { AdditionalResponseData, SampleTable } from '../common';

export default defineComponent({
    name: 'ReviewBigQueryHarvester',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<BigQueryHarvesterConfiguration>,
            required: true,
        },
        sample: {
            type: Array as PropType<Record<string, any>>,
            default: () => [],
        },
        sampleFields: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        structure: {
            type: Object as PropType<Record<string, string>>,
            default: () => {},
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        sqlQueryPreview: {
            type: String,
            default: '',
        },
    },
    components: { AdditionalResponseData, FormBlock, SQLEditor, ValidationProvider, AlertBanner, SampleTable },
    setup(props, { emit }) {
        const bigQueryConfiguration: Ref<BigQueryHarvesterConfiguration> = computed({
            get: () => props.configuration,
            set: (newConfiguration: BigQueryHarvesterConfiguration) => {
                emit('change', newConfiguration);
            },
        });

        const freshDataColumns = computed(() =>
            props.structure
                ? Object.keys(props.structure).filter((key: string) =>
                      ['Number', 'Date'].includes(props.structure[key]),
                  )
                : [],
        );

        return {
            bigQueryConfiguration,
            RetrievalType,
            freshDataColumns,
        };
    },
});
