var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormBlock',{attrs:{"title":"Connection Details","description":"The details about how to connect to your SQL database"}},[(_vm.inVault)?_c('div',{staticClass:"flex italic text-neutral-700"},[_c('LockClosedIcon',{staticClass:"w-4 h-4 mr-2"}),_c('div',{staticClass:"flex-initial text-sm italic text-neutral-600"},[_vm._v(" Connection Details are stored and secured in Vault. ")])],1):[_c('ValidationProvider',{attrs:{"rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex items-top"},[_c('label',{staticClass:"w-40 mt-2 text-sm text-neutral-700",attrs:{"for":"database_name"}},[_vm._v("Database Name")]),_c('div',{staticClass:"flex flex-col flex-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.connectionDetails.database),expression:"connectionDetails.database"}],staticClass:"block h-10 form-input sm:text-sm sm:leading-5",attrs:{"id":"database_name","placeholder":"Enter database name","type":"text","disabled":_vm.disabled},domProps:{"value":(_vm.connectionDetails.database)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.connectionDetails, "database", $event.target.value)},function($event){return _vm.$emit('details-changed')}]}}),(errors.length > 0)?_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}])}),_c('div',{staticClass:"flex items-top"},[_c('ValidationProvider',{staticClass:"flex w-full items-top",attrs:{"rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"w-40 mt-2 text-sm text-neutral-700",attrs:{"for":"host"}},[_vm._v("Host")]),_c('div',{staticClass:"flex flex-col flex-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.connectionDetails.host),expression:"connectionDetails.host"}],staticClass:"block w-full h-10 form-input sm:text-sm sm:leading-5",attrs:{"id":"host","placeholder":"Enter host","type":"text","disabled":_vm.disabled},domProps:{"value":(_vm.connectionDetails.host)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.connectionDetails, "host", $event.target.value)},function($event){return _vm.$emit('details-changed')}]}}),(errors.length > 0)?_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{staticClass:"flex items-top",attrs:{"rules":{ required: true, min_value: 0, max: 5 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-2 ml-4 text-sm w-14 text-neutral-700",attrs:{"for":"port"}},[_vm._v("Port")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.connectionDetails.port),expression:"connectionDetails.port",modifiers:{"number":true}}],staticClass:"block w-24 h-10 form-input sm:text-sm sm:leading-5",attrs:{"id":"port","placeholder":"Enter port","type":"number","disabled":_vm.disabled},domProps:{"value":(_vm.connectionDetails.port)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.connectionDetails, "port", _vm._n($event.target.value))},function($event){return _vm.$emit('details-changed')}],"blur":function($event){return _vm.$forceUpdate()}}}),(errors.length > 0)?_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])})],1),_c('ValidationProvider',{attrs:{"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex items-top"},[_c('label',{staticClass:"w-40 mt-2 text-sm text-neutral-700",attrs:{"for":"username"}},[_vm._v("Username")]),_c('div',{staticClass:"flex flex-col flex-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.connectionDetails.username),expression:"connectionDetails.username"}],staticClass:"block h-10 form-input sm:text-sm sm:leading-5",attrs:{"id":"username","placeholder":"Enter username","type":"text","disabled":_vm.disabled},domProps:{"value":(_vm.connectionDetails.username)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.connectionDetails, "username", $event.target.value)},function($event){return _vm.$emit('details-changed')}]}}),(errors.length > 0)?_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex items-top"},[_c('label',{staticClass:"w-40 mt-2 text-sm text-neutral-700",attrs:{"for":"password"}},[_vm._v("Password")]),_c('div',{staticClass:"flex flex-col flex-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.connectionDetails.password),expression:"connectionDetails.password"}],staticClass:"block h-10 form-input sm:text-sm sm:leading-5",attrs:{"id":"password","placeholder":"Enter password","type":"password","disabled":_vm.disabled},domProps:{"value":(_vm.connectionDetails.password)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.connectionDetails, "password", $event.target.value)},function($event){return _vm.$emit('details-changed')}]}}),(errors.length > 0)?_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }