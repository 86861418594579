var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"h-20 border rounded shadow cursor-pointer",class:!_vm.isFinalized && (_vm.concept.temp.invalid || _vm.failedTransformationReason)
                ? 'bg-red-100 border-red-600'
                : 'border-gray-300 bg-white',on:{"click":_vm.conceptClicked}},[_c('div',{staticClass:"flex h-full border-transparent"},[_c('div',{staticClass:"flex flex-col",class:{
                    'left-mapping w-1/2':
                        !_vm.isFinalized || (_vm.successfulTransformations === null && _vm.failedTransformations === null),
                    'left-mapping w-2/12':
                        _vm.isFinalized && (_vm.successfulTransformations !== null || _vm.failedTransformations !== null),
                }},[_c('div',{staticClass:"p-2 m-1"},[_c('h2',{staticClass:"text-lg font-bold text-neutral-800 min-h-7"},[_c('div',{staticClass:"flex items-center space-x-2"},[(_vm.failedTransformationReason)?_c('ExclamationIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.failedTransformationReason),expression:"failedTransformationReason"}],staticClass:"w-5 h-5 text-red-600"}):_vm._e(),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.concept.source.title),expression:"concept.source.title"}],staticClass:"break-all line-clamp-1"},[_vm._v(_vm._s(_vm.concept.source.title)+" ")]),_c('span',{staticClass:"px-2 text-sm capitalize rounded text-neutral-100 bg-neutral-600"},[_vm._v(_vm._s(_vm.concept.source.type))])],1)]),_c('FieldPath',{attrs:{"field":_vm.concept.source}})],1)]),_c('div',{class:{
                    'w-8/12 ml-10':
                        _vm.isFinalized && (_vm.successfulTransformations !== null || _vm.failedTransformations !== null),
                    'w-1/2 ml-10':
                        !_vm.isFinalized || (_vm.successfulTransformations === null && _vm.failedTransformations === null),
                }},[_c('div',{staticClass:"p-2 m-1"},[_c('h2',{staticClass:"flex items-center justify-between text-lg font-bold text-neutral-800 min-h-7"},[_c('div',{staticClass:"flex flex-row items-start justify-between flex-1 overflow-hidden whitespace-nowrap"},[_c('div',{staticClass:"flex flex-row space-x-2"},[_c('div',{staticClass:"flex items-center space-x-2 overflow-hidden whitespace-nowrap"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.concept.target.title),expression:"concept.target.title"}],staticClass:"truncate"},[_vm._v(_vm._s(_vm.concept.target.title)+" ")]),(_vm.concept.transformation && _vm.concept.transformation.order)?_c('span',[_vm._v("["+_vm._s(_vm.concept.transformation.order)+"] ")]):_vm._e(),_c('span',{staticClass:"flex flex-1 px-2 text-sm capitalize rounded",class:{
                                            'text-red-700 bg-red-200': !_vm.sameSourceTargetType,
                                            'text-neutral-100 bg-neutral-600': _vm.sameSourceTargetType,
                                        }},[_vm._v(_vm._s(_vm.concept.target.type))])])]),(
                                    !_vm.isFinalized &&
                                    _vm.concept.temp &&
                                    _vm.concept.temp.invalid &&
                                    String(_vm.concept.source.id) in _vm.validationErrors
                                )?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.validationErrors[String(_vm.concept.source.id)].message),expression:"validationErrors[String(concept.source.id)].message"}],staticClass:"px-5 text-sm font-normal text-center text-white bg-red-600 rounded"},[_vm._v(" "+_vm._s(_vm.validationErrors[String(_vm.concept.source.id)].message)+" ")]):_vm._e()]),(
                                _vm.isFinalized &&
                                (_vm.successfulTransformations !== null || _vm.failedTransformations !== null)
                            )?_c('div',{staticClass:"items-center hidden float-right space-x-2 md:flex"},[(_vm.failedTransformationReasons === null && _vm.failedTransformations === null)?_c('span',{staticClass:"px-2 text-sm leading-5 border rounded",class:{
                                    'text-green-700  bg-green-100  border-green-300':
                                        !_vm.successfulTransformations || !_vm.successfulTransformations.null,
                                    'text-warn-700  bg-warn-100  border-warn-300':
                                        _vm.successfulTransformations && _vm.successfulTransformations.null,
                                }},[_c('span',{staticClass:"hidden 2xl:block"},[_vm._v(" Transformation Successfully Executed"),(_vm.successfulTransformations && _vm.successfulTransformations.null)?_c('span',[_vm._v(", but with errors/missing values transformed to null")]):_vm._e()]),_c('span',{staticClass:"hidden xl:block 2xl:hidden"},[_vm._v(" Transformation Successfully Executed"),(_vm.successfulTransformations && _vm.successfulTransformations.null)?_c('span',[_vm._v(" with missing values")]):_vm._e()]),_c('span',{staticClass:"hidden lg:block xl:hidden"},[_vm._v(" Success "),(_vm.successfulTransformations && _vm.successfulTransformations.null)?_c('span',[_vm._v(" with missing values")]):_vm._e()]),_c('span',{staticClass:"lg:hidden"},[_vm._v("Success")])]):(_vm.noTransformations)?_c('span',{staticClass:"px-2 text-sm leading-5 border rounded text-warn-700 bg-warn-100 border-warn-300"},[_vm._v(" No Transformation Executed ")]):(_vm.failedTransformations && _vm.failedTransformations.errorCode === null)?_c('span',{staticClass:"px-2 text-sm leading-5 border rounded",class:{
                                    'text-green-700  bg-green-100  border-green-300': !_vm.failedTransformations.null,
                                    'text-warn-700  bg-warn-100  border-warn-300': _vm.failedTransformations.null,
                                }},[_vm._v(" Transformation Successfully Executed"),(_vm.failedTransformations.null)?_c('span',[_vm._v(" with missing values")]):_vm._e()]):_c('span',{staticClass:"px-2 text-sm leading-5 text-red-700 capitalize bg-red-100 border border-red-300 rounded"},[_vm._v(" Transformation Failed ")])]):_vm._e()]),_c('div',{staticClass:"flex items-center mt-2 space-x-1 text-sm text-neutral-600"},[_vm._l((_vm.concept.target.path),function(level,idx){return _c('div',{key:("tp-" + idx),staticClass:"flex items-center space-x-1 overflow-hidden"},[_c('div',[_vm._v(_vm._s(level))]),(idx < _vm.concept.target.path.length - 1 || _vm.concept.target.id)?_c('ChevronRightIcon',{staticClass:"w-5 h-5 ml-2 text-neutral-400"}):(idx !== 0 && !_vm.concept.target.id)?_c('button',{attrs:{"type":"button ml-1 p-1"},on:{"click":function($event){_vm.trimPath();
                                    $event.stopImmediatePropagation();}}},[_c('XIcon',{staticClass:"w-4 h-4 text-neutral-400"})],1):_vm._e()],1)}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.concept.target.title),expression:"concept.target.title"}],staticClass:"truncate text-neutral-700"},[_vm._v(" "+_vm._s(_vm.concept.target.title)+" ")])],2)])]),(_vm.isFinalized && (_vm.successfulTransformations !== null || _vm.failedTransformations !== null))?_c('div',{staticClass:"flex flex-col justify-between w-1/12 p-2 border-l border-gray-400"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Values Transformed'),expression:"'Values Transformed'"}],staticClass:"text-xs text-center text-neutral-600 line-clamp-2"},[_vm._v(" Values Transformed ")]),_c('div',{staticClass:"text-center text-md lg:text-md"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                            _vm.successfulTransformations !== null
                                ? _vm.successfulTransformations.transformed.toString()
                                : '0'
                        ),expression:"\n                            successfulTransformations !== null\n                                ? successfulTransformations.transformed.toString()\n                                : '0'\n                        "}],staticClass:"font-bold text-neutral-700"},[_vm._v(_vm._s(_vm.successfulTransformations !== null ? _vm.formatLargeNumber(_vm.successfulTransformations.transformed) : 0)+" ")]),(_vm.failedTransformations !== null)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.failedTransformations.transformed.toString()),expression:"failedTransformations.transformed.toString()"}],staticClass:"text-red-600"},[_vm._v(" (+"+_vm._s(_vm.formatLargeNumber(_vm.failedTransformations.transformed))+")")]):_vm._e()])]):_vm._e(),(_vm.isFinalized && (_vm.successfulTransformations !== null || _vm.failedTransformations !== null))?_c('div',{staticClass:"flex flex-col justify-between w-1/12 p-2 border-l border-gray-400"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Values Set to null'),expression:"'Values Set to null'"}],staticClass:"text-xs text-center text-neutral-600 line-clamp-2"},[_vm._v("Values Set to null")]),_c('div',{staticClass:"text-center text-md lg:text-md"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                            _vm.successfulTransformations !== null ? _vm.successfulTransformations.null.toString() : '0'
                        ),expression:"\n                            successfulTransformations !== null ? successfulTransformations.null.toString() : '0'\n                        "}],staticClass:"font-bold text-neutral-700"},[_vm._v(_vm._s(_vm.successfulTransformations !== null ? _vm.formatLargeNumber(_vm.successfulTransformations.null) : 0)+" ")]),(_vm.failedTransformations !== null)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.failedTransformations.null.toString()),expression:"failedTransformations.null.toString()"}],staticClass:"text-red-600"},[_vm._v(" (+"+_vm._s(_vm.formatLargeNumber(_vm.failedTransformations.null))+")")]):_vm._e()])]):_vm._e()])]),(_vm.expanded)?_c('div',{staticClass:"h-auto bg-white border-gray-400 shadow"},_vm._l((_vm.transformations),function(transformation,idx){return _c('div',{key:idx,staticClass:"flex"},[_c('div',{staticClass:"px-2 pt-1",class:{
                    'w-full': !_vm.isFinalized,
                    'w-4/6': _vm.isFinalized && _vm.failedTransformationReasons !== null,
                    'pb-1': idx === _vm.transformations.length - 1,
                }},[_c('TransformationDetails',{attrs:{"transformation":_vm.getTransformationText(
                            transformation,
                            _vm.isFinalized && (_vm.successfulTransformations !== null || _vm.failedTransformations !== null)
                        ),"isFail":_vm.isFinalized ? _vm.failedTransformationReasons !== null : null,"transformationType":transformation.type}})],1),(_vm.isFinalized && _vm.failedTransformationReasons !== null)?_c('div',{staticClass:"float-right w-2/6 px-2 pt-3 space-x-1 text-sm text-neutral-600"},[_c('span',{staticClass:"float-right px-2 text-sm text-red-700"},[_vm._v(" "+_vm._s(_vm.failedTransformationReasons)+" ")])]):_vm._e()])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }